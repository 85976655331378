import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import './EoloSteps.scss';

const EoloSteps = () => {
  return (
    <section className="common-background" id="services">
      <Container className="container-eolo-steps px-5">
        <br />
        <p>
          EOLO® es un sistema de ventilación creado para mejorar la resilencia higrotérmica de las viviendas en climas territorios con alto riesgo de aparición de mohos y hongos por exceso de humedad. Su sistema patentado de sensaje y control la hace más resistente al daño por esos vectores Sistema certificado para la ventilación y prevención de riesgo de condensación que prescribe el Artículo 4.1.10 de Mayo de 2024, de la OGUC de Chile.
        </p>
        <Container className="image-text-container">
          <Row className="top-content">
            <Col md={6}>
              <img src="/assets/img/eoloSteps/FIGURA1.png" alt="Descripción de la imagen" className="img-fluid " />
            </Col>
            <Col md={6}>
              <button className="blue-text">Solicitar servicio AQUÍ</button>
              <p className="text">
                La incorporación de EOLO®, sea en una vivienda, proyecto de vivienda o en un conjunto habitacional en cualquiera de sus fases, requiere un servicio previo de asesoría que presta gratuitamente el equipo multidisciplinario que respalda el sistema.
              </p>
            </Col>
          </Row>
          <Row className="bottom-content">
            <Col>
              <p>
                Mediante el uso de enfoques de diseño integrado y software de diseño 3D basado en BIM (Building Information
                Modeling), nuestros especialistas en conjunto con los encargados del proyecto vivienda, integran las instalaciones tomando en cuenta los requerimientos e intereses de usuarios, mandantes y los de otros sistemas y especialidades que concurren directa e indirectamente en el proyecto de instalación. 
                <br /> 
                Mediante procesos de simulación repetidos de instalación y medición de desempeños, se identifican y corrigen eventuales conflictos o problemas entre especialidades antes de ejecución, de modo de integrar EOLO® como una parte más del diseño y proyecto general de la vivienda. 
                <br /> 
                La fase de diseño, integración y pruebas de rendimiento y certificación de EOLO® es realizada por CITEC UBB con el apoyo de Laboratorios de Control Técnico de Calidad reconocidos como oficiales por MINVU. 
                <br /> 
                La empresa ABN Ingeniería ejecuta las obras con personal calificado, siguiendo protocolos probados de fabricación y montaje, bajo la supervisión de CITEC UBB, de modo de asegurar los estándares de calidad certificados del sistema a su recepción.
              </p>
            </Col>
          </Row>
        </Container>
        <br />
        <Container className="container-eolo-expansion-options">
          <h2 className='eolo-expansion-options'>TRAYECTORIA DEL SISTEMA EOLO®</h2>
        </Container>  
        <Container>
          <Card className="oval-card mb-4">
            <Card.Body>
              
              <Row className="px-4">
                <Col md={6}>
                  <Card.Img src="/assets/img/eoloSteps/FIGURA2.png" alt="Descripción de la imagen" className="img-fluid card-img-left" />
                </Col>
                <Col md={6}>
                  <Card.Text>
                  
                    <div className="title-card">DESARROLLO DEL PROYECTO</div> <br /> Se diseña y especifica técnica y económicamente la instalación de ventilación híbrido EOLO® para la vivienda y se integra previendo eventuales conflictos al proyecto de vivienda mediante tecnología BIM.
                  </Card.Text>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <br />
          <Card className="oval-card mb-4">
            <Card.Body>
              <Row className="px-4">
                <Col md={6} style={{ paddingBottom: '20px' }}>
                  <Card.Text>
                    <div className="title-card">FABRICACIÓN Y PREARMADO EN TALLER</div> <br /> Una vez confirmado el servicio de instalación del sistema, ABN Ingeniería desarrolla el trabajo de prearmado en taller de las instalaciones bajo la supervisión de CITEC UBB. El sistema con la ayuda de BIM se concibe modularmente de modo de concentrar siempre el mayor tiempo de ejecución en taller y el mínimo en obra. Se utilizan materiales de primera clase y protocolos de fabricación y control de calidad previamente acordados, que incluyen pruebas de conformidad a todas las partes y componentes del sistema.
                  </Card.Text>
                </Col>
                <Col md={6}>
                  <Card.Img src="/assets/img/eoloSteps/FIGURA3.png" alt="Descripción de la imagen" className="img-fluid" />
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <Card className="oval-card mb-4">
            <Card.Body>
              <Row className="px-4">
                <Col md={6}>
                  <Card.Img src="/assets/img/eoloSteps/FIGURA4.png" alt="Descripción de la imagen" className="img-fluid" />
                </Col>
                <Col md={6}>
                  <Card.Text>
                    <div className="title-card">DESARROLLO DEL PROYECTO</div> <br /> Se diseña y especifica técnica y económicamente la instalación de ventilación híbrido EOLO® para la vivienda y se integra previendo eventuales conflictos al proyecto de vivienda mediante tecnología BIM.
                  </Card.Text>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <Card className="oval-card mb-4">
            <Card.Body>
              <Row className="px-4">
                <Col md={6}>
                  <Card.Text>
                    <div className="title-card">PRUEBAS DE DESEMPEÑOS ANTES DE RECEPCIÓN</div> <br /> Se realizan las pruebas de hermeticidad, de caudal total y por ramas y de verificación de funcionamiento de aireadores, celosías, bloqueadores de flujo, sensores y otros. Se asegura y certifica a través de un documento oficial que todas las redes y equipos de ventilación corresponden con lo proyectado con BIM, que funcionan correctamente y cumplen con las normativas vigentes.
                  </Card.Text>
                </Col>
                <Col md={6}>
                  <Card.Img src="/assets/img/eoloSteps/FIGURA5.png" alt="Descripción de la imagen" className="img-fluid" />
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <Card className="oval-card mb-4">
            <Card.Body>
              <Row className="px-4">
                <Col md={6}>
                  <Card.Img src="/assets/img/eoloSteps/FIGURA6.png" alt="Descripción de la imagen" className="img-fluid" />
                </Col>
                <Col md={6}>
                  <Card.Text>
                  <div className="title-card">CERTIFICACIÓN Y RECEPCIÓN CITEC UBB</div> <br /> emite y entrega certificado oficial de calidad comprobada, que garantiza el óptimo funcionamiento del sistema EOLO. Por último, el usuario firma acta de recepción conforme del sistema.
                  </Card.Text>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          
          <button className="blue-text">Solicitar servicio AQUÍ</button>
        </Container>     
      </Container>
    </section>
  );
};

export default EoloSteps;
