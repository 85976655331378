import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './WhyEolo.scss';

const WhyEolo = () => {
  return (
    <section className="why-eolo-section common-background" id="whyEolo">
      <Container className="content-container px-5">
        <Row>
         
          <div className="why-eolo-content">
            <h2> QUÉ ES EOLO®</h2>
            <p className="paragraph">
              EOLO® es un sistema inteligente de ventilación para viviendas resultado de un proceso de producción de innovación basado en ciencia, prototipado y experimentación. Proceso que le tomó a los desarrolladores del sistema, tres años de experimentación y pruebas.
              <br />
              EOLO se define técnicamente como un sistema de ventilación de flujo simple modular autorregulable sujeto de la patente de invención N°202302434.
            </p>
            
          </div>
          
        </Row>
        
      </Container>
      <Container className="white-container px-5 py-4">
        <p className="paragraph">
          EOLO® es el resultado de los siguientes dos proyectos de I+D cofinanciadas por CORFO, la ANID y sus desarrolladores:
        </p>
        
        <Row>
          <img src={`${process.env.PUBLIC_URL}/assets/img/whyEolo/FIGURA3.png`} alt="Top" className="img-fluid" />
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <img src={`${process.env.PUBLIC_URL}/assets/img/whyEolo/FIGU_1.png`} alt="Top" className="img-fluid" style={{ width: '100%' }} />
          </Col>
          <Col xs={12} md={6}>
            <img src={`${process.env.PUBLIC_URL}/assets/img/whyEolo/FIGU_2.png`} alt="Top" className="img-fluid" style={{ width: '95%' }} />
          </Col>
        </Row>
        
        <br />
        
        <p> 

          Iniciativas que permitieron abordar científicamente el proceso de maduración tecnológica del sistema EOLO®, desde TRL2 a TRL 8. Primero, desde TRL2 a TRL 4, estudiando el sistema en un recinto de baño y dormitorio instalado en un módulo laboratorio de pruebas higrotérmicas bajo condiciones controladas y; desde TRL5 a TRL8 escalando el sistema para uso en una vivienda experimental de tamaño real, donde a través de un proceso de prueba-error se logró perfeccionar el sistema, verificar sus desempeños técnicos, económicos y funcionales y certificarlos finalmente.
          <br />
          Trabajo realizado en el Eco Sistema de Producción de Innovación ECOPI UBB de la Universidad del Bío-Bío, ubica-
          do en su Campus de Experimentación de la ciudad de Concepción.
        </p>

      </Container>
    </section>
  );
};

export default WhyEolo;
