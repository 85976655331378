import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import NavigationBar from './components/NavigationBar';
import ContentBlock from './components/ContentBlock';
import ConceptsDefinitions from './components/ConceptsDefinitions';
import VentilationTypes from './components/VentilationTypes';
import VentilationSystemTypes from './components/VentilationSystemTypes';
import Pathology from './components/Pathology';
import WhyEolo from './components/WhyEolo';
import NecessaryEolo from './components/NecessaryEolo';
import NecessaryIntelligentSystem from './components/NecessaryIntelligentSystem';
import EoloOffer from './components/EoloOffer';
import EoloSystemTrajectory from './components/EoloSystemTrajectory';
import EoloSteps from './components/EoloSteps';
import Footer from './components/Footer';
import './App.scss';

// Página 1
const Page1 = () => (
  <>
    <ContentBlock />
    <ConceptsDefinitions />
    <VentilationTypes />
    <VentilationSystemTypes />
    <Pathology />
  </>
);

// Página 2
const Page2 = () => (
  <>
    <WhyEolo />
    <NecessaryEolo />
    <NecessaryIntelligentSystem />
    <EoloOffer />
  </>
);

// Página 3
const Page3 = () => (
  <>
    <EoloSystemTrajectory />
  </>
);

// Página 4
const Page4 = () => (
  <>
    <EoloSteps />
  </>
);

const App = () => {
  return (
    <Router>
      <div>
        <NavigationBar />
        <Routes>
          <Route path="/" element={<Page1 />} />
          <Route path="/page2" element={<Page2 />} />
          <Route path="/page3" element={<Page3 />} />
          <Route path="/page4" element={<Page4 />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
