import React from "react";
import { Container, Row, Col, Card, Accordion } from "react-bootstrap";
import "./ConceptsDefinitions.scss";

const ConceptsDefinitions = () => {
  const tooltipData = [
    { id: 1, top: "0.7%", left: "18.3%", name: "COVs", text: "Compuestos orgánicos volátiles (COVs): emitidos por una amplia gama de materiales presentes en la vivienda y en su mobiliario. Diversos efluentes provenientes de tableros, pinturas, insumos de limpieza, pegamentos, útiles docentes, etc. Algunos son tóxicos y pueden afectar a niños de grupos vulnerables, en especial a los que sufren de asma y alergias." },
    { id: 2, top: "26.4%", left: "18.3%", name: "CO2", text: "Dióxido de carbono CO2: es un producto de la respiración humana y la combustión. El dióxido de carbono es un asfixiante simple que actúa básicamente por desplazamiento del oxígeno. A elevadas concentraciones causa dolor de cabeza, mareos, somnolencia y problemas respiratorios dependiendo de la concentración y de la duración de la exposición" },
    { id: 3, top: "52%", left: "18.2%", name: "CO", text: "Monóxido de carbono Co: es un producto de la combustión incompleta de un combustible. En aulas puede estar presente cuando existen aparatos de calefacción, estufas de leña y otros en base a hidrocarbutos, cuando la combustión y/o evacuación de gases es defectuosa. Es inoloro, incoloro e insípido y es potencialmente fatal en concentraciones relativamente bajas." },
    { id: 4, top: "0.5%", left: "77.3%", name: "H+", text: "Humedad ambiente: agua contenida en el aire ambiente interior derivada de la actividad metabólica de las personas y de la producidad en los recintos húmedos. Si este vapor no es extraído correctamente se acumula y pasa a otros recintos con el riesgo de ocurrencia de la condensación y daño a la salud de las personas y construcción." },
    { id: 5, top: "25%", left: "77.1%", name: "Olores", text: "Olores: emisiones corporales y otras provenientes de materiales y sustancias presentes en los edificos. Se relaciona con el sentido del olfato y se puede acumular a niveles desagrabales" },
    { id: 6, top: "60.5%", left: "78.3%", name: "Ext.", text: "Contaminación exterior (C.E.): contaminantes atmosféricos provenientes del exterior. Emisiones de gases y material particulado (MP10) procedentes del transporte vehicular, combustión de estufas a leña y parafinas, otros. Los principales contaminantes proveninetes de fuentes y actividades externas en la zona centro sur son los siguientes: PM10, Monóxido de Carbono (CO), Dióxido de Nitrógeno (NO2), Dióxido de Azufre (SO2) y Ozono (O3)." },
  ];

  return (
    <section className="common-background concepts-definitions-section" id="conceptsDefinitions">
      <Container className="content-container">
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <h2>
                CONCEPTOS Y DEFINICIONES SOBRE VENTILACIÓN
              </h2>
            </Accordion.Header>
            <Accordion.Body>
              <Container>
                <p className="section-description">
                  La ventilación es simplemente el proceso de remover y cambiar
                  aire malo por aire bueno. Un acto que tiene una importancia
                  vital para la salud y vida útil de la vivienda y de las personas
                  que la habitan. Para hacer conciencia sobre la ventilación,
                  comprender mejor el fenómeno y así encontrar solución a
                  problemas asociados a fallos en ese proceso, a continuación, se
                  exponen algunos elementos de contexto y conceptos básicos:
                </p>
              </Container>

              <Container className="background-section-exp">
                <h3 className="subsection-title">
                  A. FUNCIONES Y OBJETIVOS DE LA VENTILACIÓN
                </h3>
                <Row className="objectives-row">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 1200 600"
                  preserveAspectRatio="xMinYMin meet"
                  className="svg-content"
                >
                  
                    <rect x="50" y="30" width="200" height="50" fill="#2C8E90" rx="10" ry="10"/>
                    <text x="100" y="60" className="svg-text" font-size="16" font-weight="bold" fill="#FFFFFF">
                      FUNCIÓN
                    </text>

                    <rect x="300" y="30" width="550" height="50" fill="#2C8E90" rx="10" ry="10"/>
                    <text x="550" y="60" className="svg-text" font-size="16" font-weight="bold" fill="#FFFFFF">
                      OBJETIVOS
                    </text>

                    <rect x="900" y="30" width="300" height="510" fill="#2C8E90" rx="10" ry="10"/>
                    <text x="910" y="60" className="svg-text" font-size="20" fill="#FFFFFF">
                      Por ventilación se entiende el 
                    </text>
                    <text x="910" y="85" className="svg-text" font-size="20" fill="#FFFFFF">
                      proceso de suministrar o extraer 
                    </text>
                    <text x="910" y="105" className="svg-text" font-size="20" fill="#FFFFFF">
                      aire de una zona, local o edificio 
                    </text>
                    <text x="910" y="125" className="svg-text" font-size="20" fill="#FFFFFF">
                      sea de forma natural, mecánica 
                    </text>
                    <text x="910" y="145" className="svg-text" font-size="19" fill="#FFFFFF">
                      o bien combinando ambas formas 
                    </text>
                    <text x="910" y="165" className="svg-text" font-size="20" fill="#FFFFFF">
                      para cumplir con una o más de 
                    </text>
                    <text x="910" y="185" className="svg-text" font-size="20" fill="#FFFFFF">
                      las siguientes funciones y 
                    </text>

                    <text x="910" y="205" className="svg-text" font-size="20" fill="#FFFFFF">
                      objetivos, entre otros
                    </text>
                    <image x="950" y="250" width="200" height="200" href="/assets/img/conceptsDefinitons/FIGURA4.png"/>

                    <rect x="50" y="120" width="200" height="100" fill="#DDEAF0" rx="10" ry="10"/>
                    <text x="80" y="175" className="svg-text" font-size="16" font-weight="bold" fill="#353E91">
                      Proveer Oxígeno
                    </text>

                    <rect x="300" y="120" width="550" height="100" fill="#DDEAF0" rx="10" ry="10"/>
                    <text x="330" y="150" className="svg-text" font-size="20" fill="#353E91">
                      <tspan x="330" dy="0">Asegurar la correcta respiración y salud de los usuarios</tspan>
                      <tspan x="330" dy="20">junto con permitir el correcto funcionamiento de</tspan>
                      <tspan x="330" dy="20">aparatos de combustión.</tspan>
                    </text>

                    <rect x="50" y="240" width="200" height="100" fill="#DDEAF0" rx="10" ry="10"/>
                    <text x="100" y="290" className="svg-text" font-size="16" font-weight="bold" fill="#353E91">
                      Refrescar
                    </text>

                    <rect x="300" y="240" width="550" height="100" fill="#DDEAF0" rx="10" ry="10"/>
                    <text x="330" y="270" className="svg-text" font-size="20" fill="#353E91">
                      Mejorar el confort térmico de los recintos, proveyendo 
                    </text>
                    <text x="330" y="290" className="svg-text" font-size="20" fill="#353E91">
                      aire fresco para bajar la temperatura interior cuando sea
                    </text>
                    <text x="330" y="310" className="svg-text" font-size="20" fill="#353E91">
                      posible o es el caso.
                    </text>

                    <rect x="50" y="360" width="200" height="180" fill="#E7D8D0" rx="10" ry="10"/>
                    <text x="60" y="410" className="svg-text" font-size="16" font-weight="bold" fill="#353E91">
                      Remover contaminantes
                    </text>
                    <text x="70" y="425" className="svg-text" font-size="12" fill="#353E91">
                      Humedad H2O
                    </text>
                    <text x="70" y="440" className="svg-text" font-size="12" fill="#353E91">
                      <tspan className="svg-link" onClick={() => window.location.href = '#!'}>Dióxido de Carbono CO2</tspan>
                    </text>
                    <text x="70" y="455" className="svg-text" font-size="12" fill="#353E91">
                      <tspan className="svg-link" onClick={() => window.location.href = '#!'}>Monóxido de Carbono CO</tspan>
                    </text>
                    <text x="70" y="470" className="svg-text" font-size="12" fill="#353E91">
                      <tspan className="svg-link" onClick={() => window.location.href = '#!'}>Compuestos Volátiles COVs</tspan>
                    </text>
                    <text x="70" y="485" className="svg-text" font-size="12" fill="#353E91">
                      Olores
                    </text>

                    <rect x="300" y="360" width="550" height="180" fill="#E7D8D0" rx="10" ry="10"/>
                    <text x="330" y="390" className="svg-text" font-size="20" fill="#353E91">
                      Mejorar la salud de los usuarios, reduciendo el riesgo de 
                    </text>
                    <text x="330" y="410" className="svg-text" font-size="20" fill="#353E91">
                      contraer enfermedades respiratorias producidas por 
                    </text>
                    <text x="330" y="430" className="svg-text" font-size="20" fill="#353E91">
                      habitar espacios higiénicamente malsanos por falta de 
                    </text>
                    <text x="330" y="450" className="svg-text" font-size="20" fill="#353E91">
                      ventilación y;
                    </text>
                    <text x="330" y="470" className="svg-text" font-size="20" fill="#353E91">
                      Mejorar la salud de la construcción, limitando el riesgo 
                    </text>
                    <text x="330" y="490" className="svg-text" font-size="20" fill="#353E91">
                      de aparición de moho y hongos, con el fin de reducir la 
                    </text>
                    <text x="330" y="510" className="svg-text" font-size="20" fill="#353E91">
                      post venta, incrementar la vida útil y la calidad habitable
                    </text>
                    <text x="330" y="530" className="svg-text" font-size="20" fill="#353E91">
                      de la construcción.
                    </text>
                  
                  </svg>

                </Row>

                <Row className="image-row">
                  <Col md={12} className="position-relative">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/img/conceptsDefinitons/FIGURA5.png`}
                      alt="Ventilación"
                      className="img-fluid"
                      style={{ width: "65%" }}
                    />
                    {/* {tooltipData.map((item) => (
                      <button
                        key={item.id}
                        className="tooltip-button"
                        style={{ top: item.top, left: item.left }}
                      >
                        {item.name}
                        <span className="tooltip-text">{item.text}</span>
                      </button>
                    ))} */}
                    {tooltipData.map((item) => (
                      <button
                        key={item.id}
                        className="tooltip-button"
                        style={{ top: item.top, left: item.left }}
                      >
                        {item.name}
                        <span className={item.id <= 3 ? "tooltip-text-left" : "tooltip-text-right"}>
                          {item.text}
                        </span>
                      </button>
                    ))}
                  </Col>
                </Row>
              </Container>
              <Container className="space-between-containers"></Container> 
              <Container fluid className="background-section">
                <h3 className="subsection-title">
                  B. CUIDADOS DE LA VENTILACIÓN
                </h3>
                <Row>
                  <Col>
                    <p className="section-description">
                      La ventilación, es una necesidad básica para una vivienda como lo es también su medida de <a href='#!' className='unstyled-link'>eficiencia energética</a>, y hay sin duda intereses encontrados entre ambos prestaciones. Mayores tasas de remoción de aire civiado significan mayores pérdidas de calor o frío y por consiguiente mayores demandas de energía en desmedro siempre de la eficiencia energética. Con la adhesión a la Agenda 2030 y los Objetivos de Desarrollo sostenible de las Naciones Unidad, Chile adquirió el compromiso de mitigar los efectos del cambio climático, entre los que se comprende la meta de alcanzar en algún momento el estándar bajo o nulo consumo energético de su parque de edificios sin perder cualidad habitable. Meta que obligará a seguir modificando el Art. 4.1.10 de nuestra OGUC, para subir gradualmente los estándares de protección térmica y de hermeticidad al aire de los edificios a la par con la ventilación. En consecuencia, las infiltraciones de aire en los edificios y los medios de ventilación natural importarán cada vez menos en beneficio de la ventilación activa pero forzada de manera inteligente. Los edificios deberán ser cada vez más herméticos pero ventilados suficientemente de manera controlado.
                    </p>
                    <p className="section-description">
                      Principios que inspiran a EOLO®, un sistema con la capacidad para proveer aire nuevo en la medida justa: ni mucho como para producir corrientes molestas y perder calor en exceso, ni poco como para no poder remover la humedad dañina que causa enfermedades y el deterioro de la construcción.
                    </p>
                    
                  </Col>
                  
                </Row>
              </Container>   
              <Container className="space-between-containers"></Container>
              <Container fluid className="background-section-exp">
                <h3 className="subsection-title">
                  C. LA EXPERIENCIA INTERNACIONAL
                </h3>
                <p className="section-description">
                  En la década de los ochenta y luego de las primeras reformas dirigidas a mejorar la <a href='#!' className='unstyled-link'>eficiencia energética</a> de los edificios, se descubrió que el microclima de los edificios puede enfermar a sus ocupantes. Se acuñó el término de "síndrome del edificio enfermo", para denominar a aquellos edificios que, por sus bajos niveles de ventilación interior, contienen aire enrarecido con altos niveles de vapor de agua, dióxido de carbono, contaminantes, virus y bacterias (Redlich, Sparer & Cullen, 1997). Recientes estudios publicado en la revista Indoor Air, editada por la International Society of Indoor Air Quality and Climate (ISIAQ), concluyen que las acciones realizadas para ahorrar energía que reducen ventilación, tienen potencial de incrementar la exposición a contaminantes del aire interior y las tasas de transmisión de enfermedades infecciosas (Spengler, 2012)(Requia et al., 2022)(Mullen et al., 2020). Por otro lado, los niveles bajos de ventilación se asocian casi siempre con un aumento de riesgo de alergias e infecciones respiratorias. Así mismo, una deficiente ventilación se asocia también con una disminución de rendimiento escolar y laboral (Wargocki & Wyon, 2017). 
                </p>
              </Container>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Container>
    </section>
  );
};

export default ConceptsDefinitions;
