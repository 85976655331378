import React from 'react';
import { Container, Row, Col, Accordion } from 'react-bootstrap';
import './Pathology.scss';

const Pathology = () => {
  return (
    <section className="common-background concepts-definitions-section" id="pathology">
      <Container className="content-container">
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <h2>
                PATOLOGÍAS DE LA CONSTRUCCIÓN Y LA SALUD DE LAS PERSONAS Y DE LA VIVIENDAS
              </h2>
            </Accordion.Header>
            <Accordion.Body>
              <br />
              <Container className="px-5">
                <Row>
                  <Col md={4}>
                    <img src={`${process.env.PUBLIC_URL}/assets/img/pathology/FIGURA16.png`} alt="Image 1" className="img-fluid" />
                    <img src={`${process.env.PUBLIC_URL}/assets/img/pathology/FIGURA17.png`} alt="Image 2" className="img-fluid" />
                  </Col>
                  <Col md={8}>
                    <p className="custom-paragraph">
                      El término patología tiene su origen en la ciencia médica y se refiere al estudio sistemático de las enfermedades con el objetivo de comprender sus causas, síntomas y tratamientos. Este término se usa en las ciencia de la construcción para referirse al estudio de anomalías que sufren las estructuras, elementos y el funcionamiento de la edificación, que causan mal desempeño, deterioro prematuro y pérdida de vida útil de la construcción, además de problemas de salud a los ocupantes.
                    </p>
                    <p className="custom-paragraph">
                      En la década de los ochenta y luego de las primeras reformas a las ordenanzas de las construcción dirigidas a mejorar la <a href='#!' className='unstyled-link'>eficiencia energética</a> de los edificios, se descubrió que el microclima de los edificios pueden enfermar a sus ocupantes. Se acuñó el término “Síndrome del Edificio Enfermo” (SEE), para denominar a aquellos edificios que, por sus bajos niveles de ventilación interior, contienen aire enrarecido con alos niveles de vapor de agua, dióxido de carbono, contaminantes, virus y bacterias (Redlich, Sparer & Cullen, 1997). Bajos niveles de ventilación resultados de medidas introducidas para hacer más eficientes energéticamente a los edificios.
                    </p>
                    <p className="custom-paragraph">
                      Los principales problemas de salud en el interior de las viviendas mal ventiladas son: el asma, rinitis y eccema, causados por la exposición prolongadas, de hongos filamentosos en suspensión. El hongo de mayor preocupación es el Stachybotrys Chartarum, que es un moho negro que crece en zonas saturadas de humedad, materiales de construcción con alto contenido de celulosa, revestimientos de paredes (placas de yeso) y tejas. La exposición a altos niveles de este hongo puede causar síntomas similares a los del resfriado, erupciones cutáneas y agravamiento del asma. Altas concentraciones de esporas de hongos como Cladosporium y Serpula Lacrymans, Penicillium chryso genum y Penicillium cyclopium pueden producir neumonitis broncopulmonar alérgica y otras enfermedades "típicas de periodo invernal" en la zona centros sur de Chile (Singh et al., 2010; Gómez et al, 2021; Barnes; 2019) 
                    </p>
                    <p>
                      <img src={`${process.env.PUBLIC_URL}/assets/img/pathology/FIGURA18.png`} alt="Image 2" className="img-fluid img-right" />
                    </p>
                  </Col>
                </Row>
                
                <Row>
                  <p className="custom-paragraph">
                    El moho tóxico y sus esporas en el aire producen toxinas causantes de los efectos alérgicos en las personas y se caracteriza por su olor a humedad penetrante, afectando la calidad del aire interior (IAQ), y la salud de los ocupantes. Existe un fuerte vínculo entre la humedad en los edificios y crecimiento de moho y enfermedades respiratorias.
                  </p>
                  <p className="custom-paragraph">
                    El moho puede crecer en cualquier tipo de material de construcción orgánico como la madera y sus derivados, placas de yeso, papel, revestimientos, adhesivos, resinas, muebles, etc (Ruíz et al., 2022). Su desarrollo se produce en temperaturas entre 15°C y 32°C y <a href='#!' className='unstyled-link'>humedad relativa</a> superior al 65% (Silveira et al., 2019).
                  </p>
                  <p className="custom-paragraph">
                    Los altos niveles de humedad en los edificios, favorecen el desarrollo de los mohos y la degradación de importantes propiedades físico-mecánicas de los materiales, como la resistencia mecánica y térmica y la servicialidad estructural de elementos y componentes de la construcción.
                  </p>
                  <p className="custom-paragraph">
                    Una de las formas más efectivas y prácticas de limitar el desarrollo de moho y sus consecuencias es evitar que la humedad alcance niveles de inicio de condensación en cada localidad o zona térmica del territorio nacional. Los test y pruebas realizados a EOLO dan cuenta que el sistema logra cumplir con esos estándares de funconamiento plenamente.
                  </p>
                  <br />
                </Row>
              </Container>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Container>
    </section>
  );
};

export default Pathology;
