import React from 'react';
import { Container, Row, Col, Card, Accordion } from 'react-bootstrap';
import './VentilationSystemTypes.scss';

const VentilationSystemTypes = () => {
  return (
    <section className="common-background ventilation-system-types-section concepts-definitions-section" id="ventilationSystemTypes">
      <Container className="content-container">
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <h2>TIPOS DE SISTEMA DE VENTILACIÓN</h2>
            </Accordion.Header>
            <Accordion.Body>
              <Container className="px-4">
                <p className="section-description">
                  Un sistema de ventilación se puede definir como un arreglo formado por dispositivos, redes y equipos concertados inteligentemente o no para intercambiar aire viciado por aire exterior fresco. De forma general los sistemas de ventilación se pueden clasificar en los siguientes dos tipos generales: Los de flujo simple y los de flujo doble, con diversos subtipos según función y/o forma de regulación, a saber:
                </p>
              </Container>

              <Container className="px-5">
                <Row>
                  <Col md={8}>
                    <Accordion defaultActiveKey="0">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>
                          <h2 className="header-title">SISTEMA DE VENTILACIÓN DE FLUJO SIMPLE</h2>
                        </Accordion.Header>
                        <Accordion.Body className="custom-sistema-ventilacion">
                          <Container>
                            <p className="custom-paragraph">
                              (a) <a href='#!' className='unstyled-link'>Sistema de flujo simple no regulable</a>: forma muy básica de ventilación, el aire se mueve naturalmente a través de rejillas u otros dispositivos previstos para ventilar no regulables. La ventilación se produce básicamente por infiltración y aberturas de puertas y ventanas.

                            </p>
                            <p className="custom-paragraph">
                              (b) <a href='#!' className='unstyled-link'>Sistema de flujo simple regulable manualmente</a>: forma básica de ventilación similar a la anterior con la diferencia de que es posible regular manualmente el ingreso o extracción de aire a través de las rejillas.
                            </p>
                            <p className="custom-paragraph">
                              (c) <a href='#!' className='unstyled-link'>Sistema de flujo de extracción mecánica simple con entrada de aire natural</a>: formado por un extractor que normalmente se ubica en la parte superior de un muro o en el elemento de cielo de la techumbre de una dependencia, ejemplo recinto de baños o cocina, más aberturas de ingreso, normalmente aireadores o rejillas ubicadas en el extremo opuesto inferior al extractor. La depresión generada permire idealmente "barrer los contaminantes" del recinto y atraer los de otros contiguos a través de pasillos y celosías de puertas. En la zona centro sur el contaminante más importante a controlar es sin duda la humedad, por otro lado, el barrido será siempre más eficiente cuando el aire nuevo que ingresa al recinto proviene directamente del exterior.
                              <br />
                              Cuando la depresión generada no es suficiente, algo que es muy frecuente, la humedad o CO2 permite más tiempo en el recinto, pudiendo pasar a otros contiguos (<a href='#!' className='unstyled-link'>contaminación cruzada</a>) e incrementar los niveles de humedad con <a href='#!' className='unstyled-link'>riesgo de condensación</a> y aparición de moho en las paredes y/o de incrementar los niveles de CO2, y con lo mismo afectar la calidad del aire interior.
                            </p>
                            <p className="custom-paragraph">
                              (d) <a href='#!' className='unstyled-link'>Sistema de flujo de extracción mecánica simple con salida de aire natural</a>: similar al anterior, pero en este caso se impulsa aire nuevo al recinto que escapa también a través de distintos dispositivos incluso por exfiltración de aire a través de las juntas de paredes, muros y ventanas, producto de la depresión generadad. Estos sistemas de aportación se suelen utilizar en dependencias de gran longitud entre los puntos de entrada y salida del aire viciado, es menos efectivo además pues la sobrepresión generada puede provocar fugas y <a href='#!' className='unstyled-link'>contaminación cruzada</a> a lo largo del recorrido. 
                            </p>
                            <p className="custom-paragraph">
                              (e) <a href='#!' className='unstyled-link'>Sistema de flujo simple modular autoregulable</a>: formado por redes, equipos y sensores adecuados, con la capacidad para controlar permanentemente la ventilación en varias dependencias o recintos a la vez, evitando la contaminación cruzada que se produce, por ejemplo, cuando vapores y olores de baños y cocina migran hacia los recintos secos de dormitorio. El aire se impulsa o extrae de recintos según necesidad determinada por sistemas inteligentes de sensaje y controles integrados entre ellos para atender necesidades de ventilación multizona.
                            </p>
                          </Container>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </Col>
                  <Col md={4} className="d-flex justify-content-center align-items-center">
                    <img src={`${process.env.PUBLIC_URL}/assets/img/ventilationSystemTypes/FIGURA14.png`} alt="Flujo Simple" className="img-fluid" />
                  </Col>
                  
                </Row>
                <br />
                <Row>
                  <Col md={8}>
                    <Accordion defaultActiveKey="0">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header className="header-sistema-ventilacion">
                          <h2 className="header-title">SISTEMA DE VENTILACIÓN DE FLUJO DOBLE</h2>
                        </Accordion.Header>
                        <Accordion.Body className="custom-sistema-ventilacion">
                          <Container>
                            <p className="custom-paragraph">
                              (f) <a href='#!' className='unstyled-link'>Sistema de flujo doble con demanda de ventilación controlada (DVC)</a>: formado con dos redes totalmente diferenciadas entre sí, una de aportación y una de extracción mecánica controladas bajo demanda. Cada una de estas redes incorpora un ventilador, ya sea para extraer o bien para aportar aire. Al tratarse de una aportación mecánica, el aire introducido puede ser debidamente acondicionado (filtrado, precalentado). La DVC es un tipo avanzado de sistema inteligente que permite a través de sensores, sondas y compuertas, aportar y extraer en cada momento el caudal que se requiere según las necesidades reales de cada estancia.
                            </p>
                          </Container>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </Col>
                  <Col md={4} className="d-flex justify-content-center align-items-center">
                    <img src={`${process.env.PUBLIC_URL}/assets/img/ventilationSystemTypes/FIGURA15.png`} alt="Flujo Doble" className="img-fluid" />
                  </Col>
                  
                </Row>
                
              </Container>
              <Container className="px-4">
                <p className="section-description">
                  Los sistemas (e) y (f) son apropiados para atender los requerimientos normativos de ventilación de viviendas, los (c) y (d) son apropiados para atender las necesidades de recintos particulares pero no de viviendas y los (a) y (b) no son ya recomendables.
                </p>
              </Container>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Container>
    </section>
  );
};

export default VentilationSystemTypes;
