import React, { useState } from 'react';
import { Container, Row, Col, Button, Carousel } from 'react-bootstrap';
import './ContentBlock.scss';


const ContentBlock = ({ title, text, imageSrc, reverse, blueText }) => {

  
  return (
    <section className="common-background">
      <Container className="container-content-block-one">
        <h2>SISTEMA EOLO® DE VENTILACIÓN INTELIGENTE DE VIVIENDAS</h2>
        <Row>
          <Col md={4}>
            <p>EOLO® posee la capacidad para remover contaminantes entregando dosis de aire necesarias (cantidad mínima aceptables), cuando es necesario (en tiempo y duración) y en el lugar necesario (recintos secos y/o húmedos).</p>
            <img src={`${process.env.PUBLIC_URL}/assets/img/contentBlock/FIGURA1.png`} alt="FIGURA1" className="img-fluid w-50 mx-auto d-block" />
          </Col>
          <Col md={8}>
            <Carousel>
              <Carousel.Item>
                <iframe
                  width="100%"
                  height="450"
                  src="https://www.youtube.com/embed/98b0qcPkJqA"
                  title="EOLO - VIDEO 1"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </Carousel.Item>
              <Carousel.Item>
                <iframe
                  width="100%"
                  height="450"
                  src="https://www.youtube.com/embed/OGTctI1u7ao" 
                  title="EOLO - VIDEO 2"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </Carousel.Item>
            </Carousel>
          </Col>

        </Row>
      </Container>
      <Container className="space-between-containers"></Container> 
      <Container className="container-content-block-two">
        <Row>
          <Col md={8}>
            <Carousel>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={`${process.env.PUBLIC_URL}/assets/img/contentBlock/Como-elimina-la-humedad-en-sotanos-y-trasteros_SP.png`}
                  alt="FIGURA1"
                />
    
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={`${process.env.PUBLIC_URL}/assets/img/contentBlock/Como-elimina-la-humedad-en-sotanos-y-trasteros_SP.png`}
                  alt="FIGURA2"
                />
      
              </Carousel.Item>
            </Carousel>
          </Col>
          <Col className="col-content-block-moho" md={4}>
            <h2>VIVIENDA LIBRE DE MOHO, HONGOS Y ENFERMEDADES</h2>
            <p>
              EOLO® reduce el riesgo de la condensación, el tipo de humedad con mayor incidencia en la post venta de vivienda de la zona centro sur del territorio nacional, que afecta además la salud de los usuarios.
            </p>
          </Col>
        </Row>
      </Container>
      <Container className="space-between-containers"></Container> 
      
      <Container className="container-content-block-three">
        <Row>
          <Col>
            <h2>SISTEMA EOLO® DE VENTILACIÓN INTELIGENTE DE VIVIENDAS</h2>
          </Col>
        </Row>
        <Row>
          <Col>
            <img className="decreto-image" src={`${process.env.PUBLIC_URL}/assets/img/contentBlock/FIGURA3.png`} alt="FIGURA3" />
            <div className="highlighted-div">
              <p>
                EOLO® asegura <a href='#!' className='unstyled-link'>renovaciones de aire</a> para el conjunto de recintos secos y húmedo de la vivienda, compatibles con los requisitos de <a href='#!' className='unstyled-link'>transmitencia térmica</a> máxima, condensación superficial e intersiticial, infiltración de aire y ventilación que establece el Art.4.1.10 de mayo de 2024 de la Ordenanza General de Urbanismo y Construcciones (OGUC).
              </p>
              <button className="blue-text">Descargar</button>
            </div>
          </Col>
        </Row>
      </Container>
      <Container className="space-between-containers"></Container> 
    </section>
  );
};

export default ContentBlock;
