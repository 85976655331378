import React from 'react';
import { Container, Row, Col, Card, Accordion } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFan, faWind, faTint, faShower, faSmoking, faUtensils, faBug, faCloud, faRadiation, faSprayCan } from '@fortawesome/free-solid-svg-icons';
import './VentilationTypes.scss';
import { width } from '@fortawesome/free-solid-svg-icons/fa0';

const VentilationTypes = () => {
  return (
    <section className="common-background ventilation-types-section concepts-definitions-section" id="ventilationTypes">
      <Container className="content-container">
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <h2>TIPOS DE VENTILACIÓN</h2>
            </Accordion.Header>
            <Accordion.Body>
              <Container>
                <p className="section-description">
                  Según principios y necesidades a cubrir se distinguen, a lo menos los siguientes tipos:
                </p>
              </Container>

              <Container className="px-5">
                
                <h3 className="subsection-title">
                  A. SEGÚN LA FORMA EN QUE SE EXTRAEN CONTAMINANTES SE DISTINGUEN LOS SIGUIENTES TIPOS DE VENTILACIÓN
                </h3>
                
                <Row className="objectives-row">
                  <Col md={4} className="text-center d-flex flex-column align-items-center">
                    <img src="/assets/img/ventilationTypes/FIGURA6.png" alt="Ventilación inteligente" className="img-fluid small-image" />
                    <Card className="function-card flex-grow-1">
                      <Card.Body>
                        <Card.Text>
                          Dirigida a eliminar el agente contaminante en el mismo foco de generación, impidiendo así su dispersión por el local, ejemplo de la extracción de vapores de vappores de agua y olores de baños y cocinas.
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col md={4} className="text-center d-flex flex-column align-items-center">
                    <img src="/assets/img/ventilationTypes/FIGURA7.png" alt="Ventilación inteligente" className="img-fluid small-image" />
                    <Card className="function-card flex-grow-1">
                      <Card.Body>
                        <Card.Text>
                          Dirigida a reducir el nivel de contaminación en un recinto mezclando aire interior contaminado con aire exterior supuestamente sin contaminar. Medio que se utiliza normalmente para bajar la humendad o nivel de CO2 en recintos secos, pero también de apoyo a los sistema de extracción localizada de baños y cocinas. Nótese que en localidades con alta humedad climática se requerirá siempre ingresar mayores caudales de aire para evitar la condensación independiente del número de personas y tamaño de la viviendo. ¿Cuánto más o menos aire? Eso, lo determina en cada momento la inteligencia en sensaje y control incorporado a EOLO de modo de entregar las dosis de aire justas siempre. 
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col md={4} className="text-center d-flex flex-column align-items-center">
                    <img src="/assets/img/ventilationTypes/FIGURA8.png" alt="Ventilación inteligente" className="img-fluid small-image" />
                    <Card className="function-card flex-grow-1">
                      <Card.Body>
                        <Card.Text>
                          Cualquier tipo de ventilación que se activa cuando es estrictamente necesario y por el tiempo también necesario. Se activa mediante sensaje y control automático cuando un contaminante, ejemplo humedad, ambiente, CO2, alcanza un nivel crítico y se desactiva cuando la contaminación desciende hasta niveles no riesgosos.
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Container>
              <Container className="px-5">
                <h3 className="subsection-title">B. SEGÚN EL TIPO Y LA DINÁMICA DE LA CONTAMINACIÓN SE PRACTICAN GENERALMENTE LOS SIGUIENTES TIPOS DE VENTILACIÓN</h3>
                <Container className="ventilacion-puntual">
                  <Row className="contaminant-row">
                    <Col md={4}>
                      <img src={`${process.env.PUBLIC_URL}/assets/img/ventilationTypes/contaminantes_perceptibles.png`} alt="Contaminantes" className="img-fluid" style={{ height: "400px" }} />
                    </Col>
                    <Col md={8} className="contaminant-requirement-block">
                      <div className="contaminant-requirement">
                        <h5>Requieren:</h5>
                        <p>VENTILACIÓN PUNTUAL</p>
                        <p>Ventilación puntual con actuación del usuario o automática mediante sensaje y control para remover contaminantes visibles o perceptibles que se producen ocasionalmente</p>
                        <div className="contaminant-images">
                        <img src={`${process.env.PUBLIC_URL}/assets/img/ventilationStyle/olla.jpg`} alt="Ácaros" className="img-fluid" />
                        <img src={`${process.env.PUBLIC_URL}/assets/img/ventilationStyle/ducha.jpg`} alt="CO" className="img-fluid" />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>
                <Container className='ventilacion-puntual'>
                  <Row className="contaminant-row">
                    <Col md={4}>
                      <img src={`${process.env.PUBLIC_URL}/assets/img/ventilationTypes/contaminantes_imperceptibles.png`} alt="Contaminantes" className="img-fluid" style={{ height: "400px" }} />
                    </Col>
                    <Col md={8} className="contaminant-requirement-block">
                      <div className="contaminant-requirement">
                        <h5>Requieren:</h5>
                        <p>VENTILACIÓN CONTINUA</p>
                        <p>Ventilación continua para remover contaminantes invisibles o no imperceptibles, lamentablemente también presentes en las viviendas</p>
                        <div className="contaminant-images">
                          <img src={`${process.env.PUBLIC_URL}/assets/img/ventilationStyle/carbono.jpg`} alt="Ácaros" className="img-fluid" />
                          <img src={`${process.env.PUBLIC_URL}/assets/img/ventilationStyle/radon.jpg`} alt="CO" className="img-fluid" />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </Container>

              <Container className="px-5">
                <h3 className="subsection-title">C. SEGÚN LOS MOTORES QUE MOVILIZAN EL AIRE, SE DISTINGUEN LOS SIGUIENTES TIPOS DE VENTILACIÓN</h3>
                <Row className="objectives-row">
                  <Col md={4} className="text-center d-flex flex-column align-items-center">
                    <img src="/assets/img/ventilationTypes/FIGURA11.png" alt="Ventilación inteligente" className="img-fluid small-image" />
                    <Card className="function-card flex-grow-1">
                      <Card.Body>
                        <Card.Text>
                          Producida por los "motores" que provee el ambiente natural, las presiones generadas por el viento y las presiones originadas por diferenciales térmicos.
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col md={4} className="text-center d-flex flex-column align-items-center">
                    <img src="/assets/img/ventilationTypes/FIGURA12.png" alt="Ventilación inteligente" className="img-fluid small-image" />
                    <Card className="function-card flex-grow-1">
                      <Card.Body>
                        <Card.Text>
                          Producida forzadamente mediante el uso de aparatos electromecánico. Se utilizan aparatos para proveer activamente aire indistintamente en sistemas de ventilación de flujo simple y de flujo doble según requiera el sistema a implementar (sistema pertinente)
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col md={4} className="text-center d-flex flex-column align-items-center">
                    <img src="/assets/img/ventilationTypes/FIGURA13.png" alt="Ventilación inteligente" className="img-fluid small-image" />
                    <Card className="function-card flex-grow-1">
                      <Card.Body>
                        <Card.Text>
                          Producida combinando ambos tipos de ventilación. Funciona en modo natural cuando las condiciones ambientales exteriores e interiores lo permiten y, en modo mecánico, cuando el modo natural no es suficiente para mantener la consigna de control dentro de rango, por ejemplo, la <a href='#!' className='unstyled-link'>Humedad Relativa</a> bajo 85% o el CO2 bajo 1.500 ppm.
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Container>
    </section>
  );
};

export default VentilationTypes;
