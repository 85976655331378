import React, { useState, useEffect } from 'react';
import { Navbar, Nav, NavDropdown, Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import './NavigationBar.scss'; 

const NavigationBar = () => {
  const [navbarShrink, setNavbarShrink] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setNavbarShrink(true);
      } else {
        setNavbarShrink(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleNavDropdownClick = (url) => {
    window.location.href = url; // Redirige al usuario a la URL cuando se hace clic en el título del NavDropdown
  };

  return (
    <>
      <div className="top-image">
        <img src={`${process.env.PUBLIC_URL}/assets/img/navbar/IMAGEN.png`} alt="Top" className="img-fluid" />
      </div>
      <div className={`header-container ${navbarShrink ? 'navbar-shrink' : ''}`}>
        <div className="header bg-primary text-white py-2">
          <Container>
            <Row className="align-items-center">
              <Col xs="auto">
                <img src={`${process.env.PUBLIC_URL}/assets/img/navbar/logo.png`} alt="Logo" className="logo" />
              </Col>
              <Col className="text-center">
                <h1 className="header-title m-0">SISTEMA DE VENTILACIÓN INTELIGENTE DE VIVIENDAS</h1>
              </Col>
            </Row>
          </Container>
        </div>
        <Navbar bg="dark" variant="dark" expand="lg" id="mainNav" className="full-width-navbar">
          <Container>
            <Navbar.Toggle aria-controls="navbarResponsive" className="custom-toggle" /> 
            <Navbar.Collapse id="navbarResponsive" className="justify-content-center">
              <Nav className="ms-auto">
                <NavDropdown 
                  title="INICIO" 
                  className="eolo-nav-dropdown" 
                  id="eolo-nav-dropdown"
                >
                  <NavDropdown.Item as={HashLink} to="/#">SISTEMA EOLO®</NavDropdown.Item>
                  <NavDropdown.Item as={HashLink} to="/#conceptsDefinitions">CONCEPTOS Y DEFINICIONES SOBRE VENTILACIÓN</NavDropdown.Item>
                  <NavDropdown.Item as={HashLink} to="/#ventilationTypes">TIPOS DE VENTILACIÓN</NavDropdown.Item>
                  <NavDropdown.Item as={HashLink} to="/#ventilationSystemTypes">TIPOS DE SISTEMA DE VENTILACIÓN</NavDropdown.Item>
                  <NavDropdown.Item as={HashLink} to="/#pathology">PATOLOGÍAS Y LA SALUD SOBRE LAS PERSONAS Y DE LA VIVIENDA</NavDropdown.Item>
                </NavDropdown>
                <NavDropdown 
                  title="EOLO" 
                  className="eolo-nav-dropdown" 
                  id="eolo-nav-dropdown"
                >
                  <NavDropdown.Item as={HashLink} to="/page2#whyEolo">¿QUÉ ES EOLO®?</NavDropdown.Item>
                  <NavDropdown.Item as={HashLink} to="/page2#necessaryEolo">¿POR QUÉ ES NECESARIO EOLO®?</NavDropdown.Item>
                  <NavDropdown.Item as={HashLink} to="/page2#necessaryIntelligentSystem">¿POR QUÉ ES NECESARIO UN SISTEMA INTELIGENTE DE VENTILACIÓN?</NavDropdown.Item>
                  <NavDropdown.Item as={HashLink} to="/page2#eoloOffer">¿QUÉ OFRECE EOLO®?</NavDropdown.Item>
                </NavDropdown>
                <NavDropdown 
                  title="NOSOTROS" 
                  className="eolo-nav-dropdown" 
                  id="eolo-nav-dropdown"
                >
                  <NavDropdown.Item as={HashLink} to="/page3#nosotros">TRAYECTORIA DEL SISTEMA EOLO</NavDropdown.Item>
                </NavDropdown>
                <Nav.Link as={HashLink} to="/page4#services">SERVICIOS/ASESORÍAS</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </>
  );
};

export default NavigationBar;
