import React from 'react';
import { Container, Row, Col, Accordion, Card } from 'react-bootstrap';
import './NecessaryIntelligentSystem.scss';

const NecessaryIntelligentSystem = () => {
  return (
    <section className="necessary-eolo-section common-background" id="necessaryIntelligentSystem">
      <Container className="content-container">
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header className="custom-accordion-header">
              <h2 className="section-title">¿POR QUÉ ES NECESARIO UN SISTEMA INTELIGENTE DE VENTILACIÓN?</h2>
            </Accordion.Header>
            <Accordion.Body>
              <br />
              <Container className="px-5">
                <Row className="objectives-row">
                  <Col md={4} className="text-center d-flex flex-column align-items-center">
                    <img src="/assets/img/necessaryIntelligentSystem/FIGURA5-1.png" alt="Ventilación inteligente" className="img-fluid small-image" />
                    <Card className="function-card flex-grow-1">
                      <Card.Body>
                        <Card.Text>
                          1.- Por la necesidad vital de proveer aire nuevo o fresco en la medida justa: ni mucho como para producir corrientes molestas y perder calor en exceso, nimpoco como para no poder remover del ambiente la humedad dañina que causa enfermedades y el deterioro de la construcción;
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col md={4} className="text-center d-flex flex-column align-items-center">
                    <img src="/assets/img/necessaryIntelligentSystem/FIGURA5-2.png" alt="Ventilación inteligente" className="img-fluid small-image" />
                    <Card className="function-card flex-grow-1">
                      <Card.Body>
                        <Card.Text>
                          2.- Debido a que las mayores exigencias normativas de aislación térmica y de hermeticidad al aire de las envolventes, le han ido restando a la vivienda de la zona centro sur del territorio capacidad para auto ventilarse, al nivel que hoy es necesario agregar medios forzados de ventilación y además control inteligente de la humedad en todos los recintos para proveer aire nuevo en medidas justas y;
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col md={4} className="text-center d-flex flex-column align-items-center">
                    <img src="/assets/img/necessaryIntelligentSystem/FIGURA5-3.png" alt="Ventilación inteligente" className="img-fluid small-image" />
                    <Card className="function-card flex-grow-1">
                      <Card.Body>
                        <Card.Text>
                          3.- Porque una vivienda es un sistema multizona formado por recintos secos y húmedos que ejercen entre ellos influencia recíproca; por lo que es necesaria una solución para ventilación multizona con inteligencia suficiente para dosificar la extracción de aire en función de consignas de humedad y evitar la contaminación cruzada entre zonas o recintos. Estándar que ofrece EOLO® que se verifica y certifica al momento de la recepción del sistema.
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Container>
              <br />
              <img src="/assets/img/necessaryIntelligentSystem/FIGURA6.png" alt="Ventilación inteligente" className="img-fluid" />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Container>
    </section>
  );
};

export default NecessaryIntelligentSystem;
