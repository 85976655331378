// src/components/Footer.js
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faLocation, faLocationPin } from '@fortawesome/free-solid-svg-icons';
import './Footer.scss';

const Footer = () => {
  return (
    <>
      <div className="icon-bar py-1">
        <Container>
          <Row>
            <Col className="text-end">
              <img src="/assets/img/footer/citec_ubb.png" alt="Logo2" style={{ height: '70px' }} />
              <img src="/assets/img/footer/abn.png" alt="Logo2" style={{ height: '70px' }} />
            </Col>
          </Row>
        </Container>
      </div>
      <footer className="py-3">
        <div className="footer-logos">
          <img src="/assets/img/footer/logo1.png" alt="Logo1" style={{ height: '120px' }} />
          <Row className="footer-logos align-items-center">
            <Col>
              <Row>
                <Col>
                  <p className="m-0 text-white">
                    <FontAwesomeIcon icon={faPhone} className="me-2" /> +56418948930
                  </p>
                  <p className="m-0 text-white">
                    <FontAwesomeIcon icon={faEnvelope} className="me-2" /> info@mejoratuvivienda.cl
                  </p>
                </Col>
                <Col>
                  <p className="m-0 text-white">
                    <FontAwesomeIcon icon={faLocationPin} className="me-2" /> Avda. Collao 1202 Casilla 5-C, Concepción, Bío Bío
                  </p>
                </Col>
              </Row>
            </Col>
            
          </Row>
          <img src="/assets/img/footer/logo2.png" alt="Logo2" style={{ height: '130px' }} />
        </div>
      </footer>
    </>
  );
};

export default Footer;
