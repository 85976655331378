import React from 'react';
import { Container, Row, Col, Accordion, Card } from 'react-bootstrap';
import './EoloSystemTrajectory.scss';

const EoloSystemTrajectory = () => {
  return (
    <section className="common-background" id="nosotros">
      <Container className="container-eolo-system-trajectory">
        <br />
        <Container className="white-container px-4">
          <p>
            EOLO es un nuevo sistema de ventilación de viviendas resultado de un emprendimiento de los desarrolladores EIRL ABN Ingeniería y Universidad del Bío-Bío. Surge del intereses de la empresa por emprender un negocio para atender necesidades no satisfechas de la ventilación de viviendas en el mercado territorio centro sur del país y; del interés compartido por la Universidad del Bío-Bío y el Estado de Chile por apoyar la incubación y creación de empresas.
          </p>
        </Container>
        <Container className="container-eolo-expansion-options px-5">
          <h2 className='eolo-expansion-options'>TRAYECTORIA DEL SISTEMA EOLO®</h2>
        </Container>
        <Container className="image-container">
          <img src="/assets/img/eoloSystemTrajectory/FIGURA1.svg" alt="Trayectoria del sistema EOLO" className="img-fluid" />
          <br />
        </Container>

      </Container>
      
    </section>
  );
};

export default EoloSystemTrajectory;
