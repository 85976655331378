import React from 'react';
import { Container, Row, Col, Accordion } from 'react-bootstrap';
import './NecessaryEolo.scss';

const NecessaryEolo = () => {
  return (
    <section className="necessary-eolo-section common-background" id="necessaryEolo">
      <Container className="content-container">
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header className="custom-accordion-header">
              <h2>¿POR QUÉ ES NECESARIO EOLO?</h2>
            </Accordion.Header>
            <Accordion.Body>
              <img src={`${process.env.PUBLIC_URL}/assets/img/necessaryEolo/FIGURA3.png`} alt="Top" className="img-fluid" />
              <Container className="new-container">
                <p className="section-description">
                  EOLO® surge como respuesta a la necesidad de desarrollar una solución costo eficiente para prevenir la humedad de condensación en el parque de viviendas del territorio centro sur del país. Un tipo de humedad prevalente en ese territorio, responsable de altos costos de post-venta y mayores gastos en mantenimiento, además de daño a la salud de los usuarios.
                </p>
                <Row>
                  <Col md={4}>
                    <div className="d-flex align-items-center">
                      <p className="section-description">
                        Altos costos de post-venta
                      </p>
                      <img src={`${process.env.PUBLIC_URL}/assets/img/necessaryEolo/FIGURA4-1.png`} alt="Descripción" className="img-fluid small-image ms-2" />
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="d-flex align-items-center">
                      <p className="section-description">
                        Altos costos en mantenimiento
                      </p>
                      <img src={`${process.env.PUBLIC_URL}/assets/img/necessaryEolo/FIGURA4-2.png`} alt="Descripción" className="img-fluid small-image ms-2" />
                    </div>
                  </Col>
                  <Col md={4}>
                    <div className="d-flex align-items-center">
                      <p className="section-description">
                        Daño a la salud de los usuarios
                      </p>
                      <img src={`${process.env.PUBLIC_URL}/assets/img/necessaryEolo/FIGURA4-3.png`} alt="Descripción" className="img-fluid small-image ms-2" />
                    </div>
                  </Col>
                </Row>
                
              </Container>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Container>
    </section>
  );
};

export default NecessaryEolo;
