import React from 'react';
import { Container, Row, Col, Accordion, Card } from 'react-bootstrap';
import './EoloOffer.scss';

const EoloOffer = () => {
  return (
    <section className="common-background" id="eoloOffer">
      <Container className="content-container">
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header className="custom-accordion-header">
              <h2 className="section-title">¿QUÉ OFRECE EOLO®?</h2>
            </Accordion.Header>
            <Accordion.Body>
             
              <Row>
              
                <Col md={8}>
                  <Card className="custom-card small-card py-4">
                    <Row noGutters>
                      <Col md={8}>
                        <Card.Body>
                          <Card.Text className="text-left">
                            1. Caudal adaptado a las necesidades de casa estancia medidos y certificados.
                          </Card.Text>
                        </Card.Body>
                      </Col>
                      <Col md={4}>
                        <Card.Img className="small-img" src="/assets/img/eoloOffer/FIGURA8-1.png" />
                      </Col>
                    </Row>
                    <br />
                    <Row noGutters>
                      <Col md={8}>
                        <Card.Body>
                          <Card.Text className="text-left">
                            2. Riesgos de condensación muy limitados.
                          </Card.Text>
                        </Card.Body>
                      </Col>
                      <Col md={4}>
                        <Card.Img className="small-img" src="/assets/img/eoloOffer/FIGURA8-2.png" />
                      </Col>
                    </Row>
                    <br />
                    <Row noGutters>
                      <Col md={8}>
                        <Card.Body>
                          <Card.Text className="text-left">
                            3. Mayor confort y mayor ahorro energético.
                          </Card.Text>
                        </Card.Body>
                      </Col>
                      <Col md={4}>
                        <Card.Img className="small-img" src="/assets/img/eoloOffer/FIGURA8-3.png" />
                      </Col>
                    </Row>
                    <br />
                    <Row noGutters>
                      <Col md={8}>
                        <Card.Body>
                          <Card.Text className="text-left">
                            4. Aberturas de extracción con paso de sección variable de forma manual.
                          </Card.Text>
                        </Card.Body>
                      </Col>
                      <Col md={4}>
                        <Card.Img className="small-img" src="/assets/img/eoloOffer/FIGURA8-4.png" />
                      </Col>
                    </Row>
                    <br />
                    <Row noGutters>
                      <Col md={8}>
                        <Card.Body>
                          <Card.Text className="text-left">
                            5. Aire de impulsión filtrado.
                          </Card.Text>
                        </Card.Body>
                      </Col>
                      <Col md={4}>
                        <Card.Img className="small-img" src="/assets/img/eoloOffer/FIGURA8-5.png" />
                      </Col>
                    </Row>
                    <br />
                    <Row noGutters>
                      <Col md={8}>
                        <Card.Body>
                          <Card.Text className="text-left">
                            6. Otros que establece la ley.
                          </Card.Text>
                        </Card.Body>
                      </Col>
                      <Col md={4}>
                        <Card.Img className="small-img" src="/assets/img/eoloOffer/FIGURA8-6.png" />
                      </Col>
                    </Row>
                  </Card>
                  <Container className="container-eolo-expansion-options">
                    <h2 className='eolo-expansion-options'>POSIBILIDADES DE AMPLIACIÓN EOLO®</h2>
                  </Container>
                  <Card className="custom-card small-card py-4">
                    <Row noGutters>
                      <Col md={8}>
                        <Card.Body>
                          <Card.Text className="text-left">
                            1. Caudal adaptado a las necesidades de casa estancia medidos y certificados.
                          </Card.Text>
                        </Card.Body>
                      </Col>
                      <Col md={4}>
                        <Card.Img className="small-img" src="/assets/img/eoloOffer/FIGURA9-1.png" />
                      </Col>
                    </Row>
                    <br />
                    <Row noGutters>
                      <Col md={8}>
                        <Card.Body>
                          <Card.Text className="text-left">
                            2. Riesgos de condensación muy limitados.
                          </Card.Text>
                        </Card.Body>
                      </Col>
                      <Col md={4}>
                        <Card.Img className="small-img" src="/assets/img/eoloOffer/FIGURA9-2.png" />
                      </Col>
                    </Row>
                    <br />
                    <Row noGutters>
                      <Col md={8}>
                        <Card.Body>
                          <Card.Text className="text-left">
                            3. Mayor confort y mayor ahorro energético.
                          </Card.Text>
                        </Card.Body>
                      </Col>
                      <Col md={4}>
                        <Card.Img className="small-img" src="/assets/img/eoloOffer/FIGURA9-3.png" />
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col xs={12} sm={6} md={4} className="py-4">
                  <div className="image-container">
                    <img src="/assets/img/eoloOffer/FIGURA7.png" className="right-image" />
                  </div>
                </Col>
              </Row>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Container>
    </section>
  );
};

export default EoloOffer;
